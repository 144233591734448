html,
body {
  width: 100%;
  min-height: 100%;
}
body {
  background: #f3f4f7;
}
.input-date {
  color: #1c1f24;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  height: 40px;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.list-box {
  border: 1px solid #536379;
  padding: 20px;
  text-align: center;
  margin-bottom: 32px;
}
#title-box {
  font-size: 16px;
  background: #fff;
  display: table;
  margin: 0 auto;
  margin-top: -44px;
  padding: 0 20px;
}
.menu-horz {
  border: 1px solid #536379;
  margin-bottom: 16px;
}

/*--- MENU ---*/
.icon-menu {
  display: inline-block;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flex-container > div {
padding-top:1px;
}
.paginate-ctn{
    display: flex;
}
.round-effect{
  color:#2455dd;
  cursor:pointer;
/*   border:1px solid red; */
  
  font-size:13px;
  text-align:center;
  padding-left:5px;
  padding-right:5px;
  padding-top:5px;
  border-radius: 5%;
  height: 28px;
  width: 28px;
  margin:5px;
  border: solid 0.1px rgba(98, 99, 102, 0.24);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   text-decoration: none;
}

.round-effect:hover{
  text-decoration: none;
  background:#2455dd;
  color:#FFF;
}
.round-effect:hover a {
  text-decoration: none;
  color:#FFF;
}

.active{
  background:#2455dd;
  color:#FFF;
}
a:link {
  text-decoration: none;
  color:#2455dd;
}