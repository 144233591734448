.submenu {
    position: absolute;
    left: 100%;
    top: 150px;
    background-color: #fefeff;
    width: 160%;
    border: #55637a46 solid 1px ;
    border-top-right-radius: 3%;
    border-bottom-right-radius: 3%;
    
}
.submenuItem1, .submenuItem2 {
    height: 35px;
    padding: 3px;
}
.submenuItem1 {
    border-bottom: #8394ad3d solid 1px ;
}

.submenuItem1:hover, .submenuItem2:hover {
    background-color: #4a5d7c50;
}
