.step-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .step {
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: bold;
  }
  
  .step.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .line {
    width: 50px;
    height: 4px;
    background-color: #f0f0f0;
    margin: 0 5px;
    align-self: center;
  }

