.img-top-login img {
  max-width: 50%;
  text-align: center;
}
.img-top-login {
  text-align: center;
}
.box-login {
  position: relative;
  top: 50%;
  transform: translateY(100%);
}
