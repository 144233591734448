.custom-modal {
    max-height: 70%; /* Altura máxima da modal */
    overflow-y: auto; /* Adicionar barra de rolagem vertical */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-table-container {
    max-height: 700px; /* Altura máxima da tabela */
    overflow-y: auto;
    background-color: #fff; /* Adicionar barra de rolagem vertical à tabela */
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e0e0e0;
    background-color: #fff;
  }
  
  .custom-table th, .custom-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #e0e0e0;
  }
  
  .custom-tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Cor de fundo alternada para linhas pares */
  }
  